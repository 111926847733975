<template>
    <div>
        <div class="page">

            <PortalNavigation :is_session_valid="is_session_valid" />

            <b-loading v-if="is_loading_session" :is-full-page="true" v-model="is_loading_session" :can-cancel="false">
            </b-loading>

            <div v-if="!is_loading_session">
                <PortalLogin v-if="shown_component_id=='login'" />

                <Einleitung v-if="shown_component_id=='einleitung'" :bus="bus" />

                <Inspirationen v-if="shown_component_id=='inspirationen'" />

                <Techniken v-if="shown_component_id=='techniken'" />

                <Unterlagen v-if="shown_component_id=='unterlagen'" />

                <Upload v-if="shown_component_id=='upload'" />
            </div>
        </div>
        <PortalFooter :bus="bus" />
    </div>
</template>
<style>
    input {
        text-align: center;
    }

    body,
    html {
        height: 100%;
    }

    body {
        /* The image used */
        background-image: url("https://swissdominoeffect.com/img/background/domino_wallpaper_blue.png");

        /* Full height */
        height: 100%;

        /* Center and scale the image nicely */
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .subpage {
        background-color: white;


        background-image: none;
    }

    .page {
        display: flex;
        min-height: 90vh;
        flex-direction: column;
    }

    #wrapper {
        flex: 1;
    }
</style>
<script>
    import Vue from 'vue'
    import PortalLogin from './PortalLogin'
    import PortalNavigation from './PortalNavigation'
    import PortalFooter from './PortalFooter'
    import Einleitung from './subpages/Einleitung'
    import Inspirationen from './subpages/Inspirationen'
    import Techniken from './subpages/Techniken'
    import Unterlagen from './subpages/Unterlagen'
    import Upload from './subpages/Upload'
    import {
        isCookieSessionValid,
        sessionName
    } from './session_helper'
    export default {
        metaInfo: {
            title: 'Portal'
        },
        data: function () {
            return {
                is_session_valid: false,
                is_loading_session: true,
                bus: new Vue()
            }
        },
        mounted() {
            this.refreshCookieSession()
        },
        methods: {
            refreshCookieSession() {
                this.is_loading_session = true
                let token = this.$cookies.get('token')
                isCookieSessionValid(token, resp => {
                    this.is_session_valid = resp ? resp.state : false
                    this.is_loading_session = false
                    if (this.is_session_valid) {
                        this.$cookies.set('type', resp.type, '7d')
                        sessionName(token, name => {
                            this.$cookies.set('name', name, '7d')
                            this.bus.$emit('cookierefresh')
                        })
                    } else {
                        this.bus.$emit('cookierefresh')
                    }
                })
            }
        },
        computed: {
            shown_component_id() {
                if (this.is_session_valid) {
                    let subpage = this.$route.params.subpage
                    return subpage ? subpage : 'einleitung'
                } else if (this.has_session_cookie && this.is_loading_session) {
                    return 'null'
                }
                return 'login'
            },
            has_session_cookie() {
                return this.$cookies.get('token')
            }
        },
        components: {
            PortalLogin,
            PortalNavigation,
            PortalFooter,
            Einleitung,
            Inspirationen,
            Techniken,
            Unterlagen,
            Upload
        }
    }
</script>