<template>
    <b-navbar :fixed-top="false" :spaced="true" :shadow="true" :close-on-click="true">
        <template v-slot:brand>
            <b-navbar-item tag="router-link" :to="{ name: 'portal' }">
                <h1 class="title is-3">SDE <span class="orange">{{portal_subtitle}}</span></h1>
            </b-navbar-item>
        </template>
        <template #start>
        </template>

        <template v-slot:end>
            <b-navbar-item tag="div" v-if="is_session_valid">
                <div class="buttons">
                    <router-link class="button" :class="getNavigationIcon('Einleitung')" :to="{name: 'portal', params: {subpage: 'einleitung'}}">
                        Einleitung
                    </router-link>
                    <router-link class="button" :class="getNavigationIcon('Techniken')" :to="{name: 'portal', params: {subpage: 'techniken'}}">
                        Techniken
                    </router-link>
                    <router-link class="button" :class="getNavigationIcon('Inspirationen')" :to="{name: 'portal', params: {subpage: 'inspirationen'}}">
                        Inspirationen
                    </router-link>
                    <router-link class="button" :class="getNavigationIcon('Unterlagen')" :to="{name: 'portal', params: {subpage: 'unterlagen'}}">
                        Unterlagen
                    </router-link>
                    <b-button icon-left="upload" :class="getNavigationIcon('Upload')" tag="router-link" :to="{name: 'portal', params: {subpage: 'upload'}}" v-if="!is_enterprise_mode">
                        Wettbewerb
                    </b-button>
                    <b-button label="Logout" icon-left="logout" @click="logout" />
                </div>
            </b-navbar-item>
            <b-navbar-item v-else>
                <div class="buttons">
                    <a class="button" href="https://swissdominoeffect.com/">
                        Zur Webseite
                    </a>
                </div>
                </b-navbar-item>
        </template>
    </b-navbar>
</template>
<style>
    .orange {
        color: orange;
    }
    .is-orange {
        background-color: orange;
        color: white;
    }
    .is-orange:hover{
        color: white;
    }
</style>
<style scoped>
.title {
    font-family: 'geomanistbook';
}
</style>
<script>
    export default {
        props: {
            is_session_valid: {
                type: Boolean,
                required: true
            }
        },
        methods: {
            logout() {
                this.$cookies.remove('token')
                this.$cookies.remove('name')
                this.$cookies.remove('type')
                location.reload()
            },
            getNavigationIcon(button) {
                if(this.is_enterprise_mode) {
                    if(button == 'Upload') {
                        return 'is-primary is-light is-outlined'
                    }
                    return 'is-primary is-light'
                }
                switch(button) {
                    case 'Upload':
                        return 'is-danger'
                    case 'Einleitung':
                        return 'is-orange'
                    case 'Techniken':
                        return 'is-success'
                    case 'Inspirationen':
                        return 'is-primary'
                    case 'Unterlagen':
                        return 'is-dark'

                }
            }
        },
        computed: {
            portal_subtitle() {
                if(this.is_session_valid && !this.is_enterprise_mode) {
                    return '@School'
                }
                return 'Portal'
            },
            is_enterprise_mode() {
                return this.$cookies.get('type') == 'c'
            },
            is_enterprise_navigation_loaded() {
                return this.is_enterprise_mode
            }
        }
    }
</script>