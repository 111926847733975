const levels = [{
        id: 'general',
        name: 'Allgemein',
        color: 'is-dark',
        pic: 'titelbilder/Allgemein.png',
        sub: [{
            id: 'grundlagen',
            name: 'Grundlagen',
            pic: 'titelbilder/Allgemein.png',
            media: [
                'allgemein/Bilder V1.013.png',
                'allgemein/Bilder V1.014.png',
                'allgemein/Bilder V1.015.png',
                'allgemein/Bilder V1.016.png',
                'allgemein/Bilder V1.017.png',
                'allgemein/Bilder V1.018.png',
            ]
        }]
    },
    {
        id: 'starter',
        name: 'Anfänger',
        color: 'is-info',
        pic: 'titelbilder/Sonimod.png',
        sub: [{
                id: 'sonimod',
                name: 'Sonimod',
                pic: 'titelbilder/Sonimod.png',
                media: [
                    'Sonimod_raw.mp4',
                    'sonimod/Bilder V1.009.png',
                    'sonimod/Bilder V1.010.png',
                    'sonimod/Bilder V1.011.png',
                    'sonimod/Bilder V1.012.png'
                ]
            },
            {
                id: 'flach',
                name: 'Flach',
                pic: 'flach/Bilder V2.011.png',
                media: [
                    'Flat_raw.mp4',
                    'flach/Bilder V2.010.png',
                    'flach/Bilder V2.011.png',
                ]
            },
            {
                id: '2dpyramidsimple',
                name: '2D Pyramide einfach',
                pic: 'titelbilder/Pyramide einfach.png',
                media: [
                    '2d_pyramid_simple_raw.mp4',
                    'pyramiden/Bilder V1.021.png',
                    'pyramiden/Bilder V1.022.png',
                    'pyramiden/Bilder V1.023.png',
                    'pyramiden/Bilder V1.024.png',
                    'pyramiden/Bilder V1.025.png',
                    'pyramiden/Bilder V1.026.png',
                    'pyramiden/Bilder V1.027.png',
                ]
            },
            {
                id: 'spiralesimple',
                name: 'Spirale einfach',
                pic: 'spirale/Bilder V2.007.png',
                media: [
                    'Spirale_raw.mp4',
                    'spirale/Bilder V2.006.png',
                    'spirale/Bilder V2.007.png',
                    'spirale/Bilder V2.008.png',
                    'spirale/Bilder V2.009.png',
                ]
            },
            {
                id: 'gabelung',
                name: 'Gabelung',
                pic: 'gabelung/Bilder V2.015.png',
                media: [
                    'Gabelung_raw.mp4',
                    'gabelung/Bilder V2.012.png',
                    'gabelung/Bilder V2.013.png',
                    'gabelung/Bilder V2.014.png',
                    'gabelung/Bilder V2.015.png',
                ]
            },
        ]
    },
    {
        id: 'fortgeschritten',
        name: 'Fortgeschrittene',
        color: 'is-success',
        pic: 'titelbilder/Fortgeschritten.png',
        sub: [{
                id: 'tower',
                name: 'Turm',
                pic: 'titelbilder/Turm.png',
                media: [
                    'Tower_raw.mp4',
                    'turm/Bilder V1.044.png',
                    'turm/Bilder V1.045.png',
                    'turm/Bilder V1.046.png',
                    'turm/Bilder V1.047.png',
                    'turm/Bilder V1.048.png',
                    'turm/Bilder V1.049.png',
                ]
            },
            {
                id: 'feldanstoss',
                name: 'Feldanstoss',
                pic: 'titelbilder/Fortgeschritten.png',
                media: [
                    'Feldanstoss_raw.mp4',
                    'feldanstoss/Bilder V1.019.png',
                    'feldanstoss/Bilder V1.020.png'
                ]
            },
            {
                id: '2dpyramidadvanced',
                name: '2D Pyramide fortgeschritten',
                pic: 'titelbilder/Pyramide fortgeschritten.png',
                media: [
                    '2d_pyramid_advanced_raw.mp4',
                    'pyramiden/Bilder V1.028.png',
                    'pyramiden/Bilder V1.029.png',
                    'pyramiden/Bilder V1.030.png',
                    'pyramiden/Bilder V1.031.png',
                    'pyramiden/Bilder V1.032.png',
                    'pyramiden/Bilder V1.033.png',
                    'pyramiden/Bilder V1.034.png',
                    'pyramiden/Bilder V1.035.png',
                    'pyramiden/Bilder V1.036.png',
                ]
            },
            {
                id: 'speedwall',
                name: 'Speedwall',
                pic: 'titelbilder/Speedwall.png',
                media: [
                    'Speedwall_raw.mp4',
                    'pyramiden/Bilder V1.037.png',
                    'pyramiden/Bilder V1.038.png',
                    'pyramiden/Bilder V1.039.png',
                    'pyramiden/Bilder V1.040.png',
                    'pyramiden/Bilder V1.041.png',
                    'pyramiden/Bilder V1.042.png',
                    'pyramiden/Bilder V1.043.png',
                ]
            },
            {
                id: 'spiraleadvanced',
                name: 'Spirale fortgeschritten',
                pic: 'spirale/Bilder V2.017.png',
                media: [
                    'Spirale_Advanced_raw.mp4',
                    'spirale/Bilder V2.016.png',
                    'spirale/Bilder V2.017.png',
                ]
            },
            {
                id: 'clappeffect',
                name: 'Klappeffekt',
                pic: 'klappeffekt/Bilder V2.005.png',
                media: [
                    'Clapline_raw.mp4',
                    'klappeffekt/Bilder V2.001.png',
                    'klappeffekt/Bilder V2.002.png',
                    'klappeffekt/Bilder V2.003.png',
                    'klappeffekt/Bilder V2.004.png',
                    'klappeffekt/Bilder V2.005.png',
                ]
            },
        ]
    },
    {
        id: 'profi',
        name: 'Profi',
        color: 'is-orange',
        pic: 'titelbilder/Profi.png',
        sub: [{
                id: 'round_tower',
                name: 'Rundturm',
                pic: 'turm/Bilder V2.020.png',
                media: [
                    'Round_Tower_raw.mp4',
                    'turm/Bilder V2.018.png',
                    'turm/Bilder V2.019.png',
                    'turm/Bilder V2.020.png',
                    'turm/Bilder V2.021.png',
                ]
            },
            {
                id: 'square_tower',
                name: 'Eckiger Turm',
                pic: 'turm/Bilder V2.038.png',
                media: [
                    'Square_Tower_raw.mp4',
                    'turm/Bilder V2.030.png',
                    'turm/Bilder V2.031.png',
                    'turm/Bilder V2.032.png',
                    'turm/Bilder V2.033.png',
                    'turm/Bilder V2.034.png',
                    'turm/Bilder V2.035.png',
                    'turm/Bilder V2.036.png',
                    'turm/Bilder V2.037.png',
                    'turm/Bilder V2.038.png',
                    'turm/Bilder V2.039.png',
                ]
            },
            {
                id: 'blume',
                name: 'Blume',
                pic: 'blume/Bilder V2.046.png',
                media: [
                    'Leaf_raw.mp4',
                    'blume/Bilder V2.040.png',
                    'blume/Bilder V2.041.png',
                    'blume/Bilder V2.042.png',
                    'blume/Bilder V2.043.png',
                    'blume/Bilder V2.044.png',
                    'blume/Bilder V2.045.png',
                    'blume/Bilder V2.046.png',
                ]
            },
            {
                id: '3dpyramid',
                name: '3D Pyramide',
                pic: '3dpyramide/Bilder V2.029.png',
                media: [
                    '3d_pyramid_raw.mp4',
                    '3dpyramide/Bilder V2.022.png',
                    '3dpyramide/Bilder V2.023.png',
                    '3dpyramide/Bilder V2.024.png',
                    '3dpyramide/Bilder V2.025.png',
                    '3dpyramide/Bilder V2.026.png',
                    '3dpyramide/Bilder V2.027.png',
                    '3dpyramide/Bilder V2.028.png',
                    '3dpyramide/Bilder V2.029.png',
                ]
            },
        ]
    }
]

const getTechnique = function (id) {
    for (let level of levels) {
        for (let i = 0; i < level.sub.length; i++) {
            let sub = level.sub[i]
            if (sub.id == id) {
                return sub
            }
        }
    }
    return null
}

const getLevel = function (id) {
    let matches = levels.filter(lvl => lvl.id == id)
    if (matches.length > 0) {
        return matches[0]
    }
    return null
}

const isSinglePage = function (id) {
    let sub = getLevel(id).sub
    return sub ? sub.length == 1 : false
}

// add fieldplans to random ideas
const randomIdea = function () {
    let randomLevel = null;
    while (!randomLevel || randomLevel.id == 'general' || !randomLevel.sub) {
        randomLevel = levels.random()
    }
    return {
        level_id: randomLevel.id,
        technique: randomLevel.sub.random()
    }
}

Array.prototype.random = function () {
    return this[Math.floor((Math.random() * this.length))];
}

export {
    levels,
    getLevel,
    getTechnique,
    randomIdea,
    isSinglePage
}