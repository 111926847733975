<template>
    <div class="subpage">
        <section class="hero is-medium is-dark has-background">
            <img :src="`https://swissdominoeffect.com/img/background/${header_image}`"
                class="is-transparent hero-background" />
            <div class="hero-body">
                <div class="container">
                    <h1 class="title is-1">
                        Einleitung
                    </h1>
                </div>
            </div>
        </section>
        <section class="section">
            <div class="container is-max-desktop">
                <h3 class="title is-3 ">Herzlich Willkommen <span class="orange-text">{{portal_name}}</span></h3>
                <div class="content is-large">
                    <p>
                        Herzlich Willkommen in unserem <b>{{is_enterprise_portal ? 'SDE Portal' : 'SDE @School Portal'}}!</b> Sämtliche Inhalte sollen Ihnen dazu
                        dienen,
                        Ihren eigenen DominoDay selbstständig vorzubereiten und durchzuführen.<br>
                        <br>
                        In der ersten Rubrik Techniken finden Sie alle benötigen Informationen und Erklärungen, welche
                        sich auf das Dominobauen direkt beziehen. Nebst allgemeinen Grundlagen, welche zu beachten sind,
                        finden Sie eine breite Palette an Techniken und Tricks eingeteilt in Anfänger, Fortgeschrittene
                        und Profis. Jede Technik wird mit Bild- und Videomaterial unterstützt.<br>
                        <br>
                        Die Rubrik Inspirationen ist eine Sammlung unserer eigenen Projekte, welche die Schülerinnen und
                        Schülern inspirieren soll, neue Ideen umzusetzen.<br>
                        <br>
                        Verschiedene Dokumente, wie Workshop Instruktionen, Bildvorlagen und Ausmalvorlagen finden Sie
                        in der Rubrik Unterlagen.
                    </p>
                    <hr>
                    <div class="tile is-ancestor">
                        <div class="tile is-vertical is-12">
                            <div class="tile">
                                <div class="tile is-parent">
                                    <article class="tile is-child notification" :class="getTileColorClass(0)">
                                        <p class="title">Techniken</p>
                                        <p class="subtitle">Alle wichtigen Tipps & Tricks zum Bauen von Kettenreaktionen
                                            mit
                                            Dominosteinen.</p>
                                        <router-link class="button is-medium"
                                            :to="{name: 'portal', params: {subpage: 'techniken'}}">Zu den Techniken
                                        </router-link>
                                    </article>
                                </div>
                                <div class="tile is-parent">
                                    <article class="tile is-child notification" :class="getTileColorClass(1)">
                                        <div class="content">
                                            <p class="title">Inspirationen</p>
                                            <p class="subtitle">Sieh dir tolle Projekte mit Dominosteinen an und lass
                                                dich
                                                inspirieren.</p>
                                            <router-link class="button is-medium"
                                                :to="{name: 'portal', params: {subpage: 'inspirationen'}}">Zu den
                                                Inspirationen</router-link>
                                        </div>
                                    </article>
                                </div>
                                <div class="tile is-parent">
                                    <article class="tile is-child notification" :class="getTileColorClass(2)">
                                        <div class="content">
                                            <p class="title">Unterlagen</p>
                                            <p class="subtitle">Unterlagen für den Schulunterricht oder Eigengebrauch.
                                            </p>
                                            <router-link class="button is-medium"
                                                :to="{name: 'portal', params: {subpage: 'unterlagen'}}">Unterlagen
                                                einsehen
                                            </router-link>
                                        </div>
                                    </article>
                                </div>
                            </div>
                            <div class="tile is-parent">
                                <article class="tile is-child notification" :class="getTileColorClass(3)">
                                    <p class="title">Fragen zum Portal?</p>
                                    <p class="subtitle">Bei Fragen oder Anmerkungen zum Portal, können Sie gerne mit uns
                                        in Kontakt treten.</p>
                                    <router-link class="button is-medium" :to="{name: 'kontakt'}" target="_blank">Zum
                                        Kontaktformular
                                    </router-link>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<style scoped>
    .notification {
        padding: 20px;
    }
    .orange-text {
        color: orange;
    }
</style>
<script>
    export default {
        props: ['bus'],
        data: function () {
            return {
                portal_name: ''
            }
        },
        mounted() {
            this.bus.$on('cookierefresh', () => this.portal_name = this.get_portal_name())
            this.portal_name = this.get_portal_name()
        },
        methods: {
            get_portal_name() {
                return this.$cookies.get('name')
            },
            getTileColorClass(id) {
                if(this.is_enterprise_portal) {
                    return 'is-primary is-light'
                }
                switch(id) {
                    case 0:
                        return 'is-success'
                    case 1:
                        return 'is-primary'
                    case 2:
                        return 'is-dark'
                    case 3:
                        return 'is-danger'
                }
            }
        },
        computed: {
            is_enterprise_portal() {
                return this.$cookies.get('type') == 'c'
            },
            header_image() {
                return this.is_enterprise_portal ? 'domino_wallpaper_blue.png': 'domino_wallpaper_orange.png'
            }
        }
    }
</script>