<template>
    <section class="section">
        <div class="container is-max-desktop">
            <div class="level">
                <div class="level-left">
                    <div class="level-item">
                        <h1 class="title is-2">{{data.name}}</h1>
                    </div>
                </div>
                <div class="level-right">
                    <div class="level-item">
                        <a class="button is-light" @click="reset">Zurück</a>
                    </div>
                </div>
            </div>
            <b-carousel :autoplay="false" indicator-custom :indicator-inside="false" :overlay="gallery"
                @click="switchGallery(true)">
                <b-carousel-item v-for="(item, i) in data.media" :key="i">
                    <video v-if="isVideo(i)" autoplay muted playsinline loop>
                        <source :src="getMedia(i)" type="video/mp4">
                        Your browser does not support the video tag.
                    </video>
                    <a class="image" v-else>
                        <img :src="getMedia(i)">
                    </a>
                </b-carousel-item>
                <span v-if="gallery" @click="switchGallery(false)" class="modal-close is-large" />
                <template #indicators="props">
                    <figure class="al video" :draggable="false" v-if="isVideo(props.i)">
                        <video>
                            <source :src="getMedia(props.i)" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                    </figure>
                    <figure class="al image" :draggable="false" v-else>
                        <img :draggable="false" :src="getMedia(props.i)" :title="props.i">
                    </figure>
                </template>
            </b-carousel>
        </div>
    </section>
</template>
<style scoped>
.video {
    padding-top: 0.5rem;
}
video {
    width: 100%;
}
</style>
<script>
    import {
        getTechnique
    } from './../portal_const'
    export default {
        data: function () {
            return {
                data: null,
                gallery: false,
            }
        },
        created() {
            this.data = getTechnique(this.technique_id)
        },
        methods: {
            getMedia(index) {
                let media = this.data.media[index]
                if (this.isVideo(index)) {
                    return `https://swissdominoeffect.com/videos/portal/${media}`
                }
                return `https://swissdominoeffect.com/img/portal/${media}`
            },
            isVideo(index) {
                return this.data.media[index].includes('mp4')
            },
            switchGallery(value) {
                this.gallery = value
                if (value) {
                    return document.documentElement.classList.add('is-clipped')
                } else {
                    return document.documentElement.classList.remove('is-clipped')
                }
            },
            reset() {
                this.$parent.set_technique(null)
            }
        },
        computed: {
            technique_id() {
                return this.$route.params.id
            }
        }
    }
</script>