<template>
    <section class="hero has-background" :class="[color, header_size]">
        <img :src="picture" class="is-transparent hero-background" />
        <div class="hero-body">
            <div class="container">
                <h1 class="title is-1">
                    Techniken
                </h1>
            </div>
        </div>
    </section>
</template>
<script>
    import {
        getLevel
    } from './../portal_const'
    export default {
        computed: {
            is_enterprise_portal() {
                return this.$cookies.get('type') == 'c'
            },
            picture() {
                return `https://swissdominoeffect.com/img/background/domino_wallpaper_${this.color_picture}.png`
            },
            color_picture() {
                if(this.is_enterprise_portal) {
                    return 'blue'
                }

                if (!getLevel(this.category_state)) {
                    return 'green'
                }

                switch (this.color) {
                    case 'is-dark':
                        return 'black'
                    case 'is-success':
                        return 'green'
                    case 'is-info':
                        return 'blue'
                    case 'is-orange':
                        return 'red'
                }
            },
            color() {
                let level = getLevel(this.category_state)
                if (level) {
                    return level.color
                }
                return 'is-dark'
            },
            category_state() {
                return this.$route.params.level
            },
            category_name() {
                return getLevel(this.category_state).name
            },
            //deprecrated
            technique_state() {
                return this.$route.params.id
            },
            header_size() {
                if (this.category_state) {
                    return 'is-small'
                }
                return 'is-medium'
            }
        }
    }
</script>