<template>
    <div>
        <section class="section">
            <div class="container is-max-desktop">
                <h3 class="title is-3">Kategorien</h3>
                <hr>
                <div class="columns is-multiline is-centered">
                    <div class="column is-6 has-text-centered" v-for="level in levels" :key="level.id">
                        <div class="notification" @click="set_level(level)" :class="[level.color]">
                            <img :src="`https://swissdominoeffect.com/img/portal/${level.pic}`">
                            <h2 class="title is-3">{{level.name}}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<style scoped>
    .notification {
        padding: 0 0 15px 0;
    }

    .notification .title {
        margin-top: 5px;
    }
</style>
<script>
    import {
        levels,
        isSinglePage
    } from './../portal_const'
    export default {
        methods: {
            set_level(level) {
                let level_id = level.id
                if (isSinglePage(level_id)) {
                    this.$parent.setPage(level_id, level.sub[0].id)
                } else {
                    this.$parent.set_level(level_id)
                }
            }
        },
        computed: {
            levels() {
                return levels
            },
        }
    }
</script>