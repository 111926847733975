<template>
    <div class="subpage">
        <section class="hero is-medium is-dark has-background">
            <img :src="`https://swissdominoeffect.com/img/background/domino_wallpaper_blue.png`"
                class="is-transparent hero-background" />
            <div class="hero-body">
                <div class="container">
                    <h1 class="title is-1">
                        Inspirationen
                    </h1>
                </div>
            </div>
        </section>
        <section class="section">
            <div class="container is-max-desktop">
                
                <RandomIdea />

                <h3 class="title is-3">Bilder von Dominoprojekten</h3>
                <hr>
                <b-carousel :indicator-inside="false"  indicator-custom style="margin-bottom: 30px;">
                        <b-carousel-item v-for="(pic, i) in carousel_pictures" :key="i">
                            <span class="image">
                                <img :src="pic">
                            </span>
                        </b-carousel-item>
                        <template #indicators="props">
                            <span class="al image">
                                <img :src="carousel_pictures[props.i]">
                            </span>
                        </template>
                    </b-carousel>

                <h3 class="title is-3">Videos von Dominoprojekten</h3>
                <hr>
                <div class="columns is-multiline">
                    <div class="column is-6" v-for="video in videos" :key="video">
                        <iframe width="100%" height="315" :src="`https://www.youtube.com/embed/${video}?rel=0`"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import RandomIdea from './RandomIdea'
    export default {
        data: function () {
            return {
                videos: [
                    'Fqb-TjYgvq4',
                    'plOoI0pymxY',
                    'UVrg359IpVo',
                    'X0HaUNxmf38',
                    'maxqu_X4lEw',
                    'FZ5eQpH-A7Q',
                    'eYLP2w11910',
                    'J1ugTaJejuE',
                    'VTf-xcQNMcU',
                    'pVymbxAr9jE',
                    'bXmW8tebjNI',
                    'PXT0P9tIs8Y',
                    'W7DqIJbc4cc',
                    'sY_aBXn19lg',
                    'QQr3DRnjUm8',
                    'HXALekuuVq4',
                    'vg3HQzT-Gqk',
                    '1PPk1MxqDDc',
                    'qdksAngDYwo',
                    'gb1fT8dy8JY'
                ],
                carousel_pictures: []
            }
        },
        created() {
            let directory = require.context('@/assets/img/inspiration/', true)
            directory.keys().forEach(element => {
                this.carousel_pictures.push(directory(element))
            })
        },
        components: {
            RandomIdea
        }
    }
</script>