<template>
    <div class="subpage">
        <TechnikHeader />
        <TechnikLevel v-if="view_mode == 'level'" />
        <TechnikCategories v-if="view_mode == 'categories'" />
        <TechnikView v-if="view_mode == 'technique'" />
    </div>
</template>
<style>
    .is-orange {
        background: orange;
        color: white;
    }

    .is-orange .title {
        color: white;
    }

    .notification :hover {
        cursor: pointer;
    }
</style>
<script>
    import TechnikHeader from './TechnikHeader'
    import TechnikLevel from './TechnikLevel'
    import TechnikCategories from './TechnikCategories'
    import TechnikView from './TechnikView'
    import {
        isSinglePage
    } from './../portal_const'
    export default {
        methods: {
            set_level(level_id) {
                this.setPage(level_id, null)
            },
            set_technique(technique_id) {
                let level = this.level_state
                if (!technique_id && isSinglePage(level)) {
                    this.set_level(null)
                    return
                }
                this.setPage(level, technique_id)
            },
            setPage(level_id, technique_id) {
                this.$router.push({
                    name: 'portal',
                    params: {
                        subpage: 'techniken',
                        level: level_id,
                        id: technique_id
                    }
                }).catch(() => {

                })
            }
        },
        computed: {
            view_mode() {
                if (this.technique_state) {
                    return 'technique'
                } else if (this.level_state) {
                    return 'categories'
                } else {
                    return 'level'
                }
            },
            level_state() {
                let params = this.$route.params
                if (params) {
                    return params.level
                }
                return false
            },
            technique_state() {
                let params = this.$route.params
                if (params) {
                    return params.id
                }
                return false
            }
        },
        components: {
            TechnikHeader,
            TechnikLevel,
            TechnikCategories,
            TechnikView
        }
    }
</script>