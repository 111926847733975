<template>
    <div class="subpage">
        <section class="hero is-medium is-dark has-background">
            <img :src="`https://swissdominoeffect.com/img/background/${header_image}`"
                class="is-transparent hero-background" />
            <div class="hero-body">
                <div class="container">
                    <h1 class="title is-1">
                        Klassenwettbewerb
                    </h1>
                </div>
            </div>
        </section>
        <section class="section">
            <div class="container is-max-desktop">
                <h3 class="title is-3 ">Jetzt teilnehmen</h3>
                <div class="content is-large">
                    <p>
                        Bis zum <strong>31. Mai 2024</strong> haben Sie die Möglichkeit, uns Fotos Ihres Dominoprojekts zu schicken und an unserer Verlosung teilzunehmen. 
                        Gewinnen Sie als Schulklasse einen Preis von <strong>CHF 200.-</strong> für Ihre Klassenkasse!
                    </p>
                    <p>
                        Die eingeschickten Bilder werden im Rahmen eines Wettbewerbs auf unserer Instagram-Seite veröffentlicht. 
                        Die Schulklasse deren Post nach einer Woche am meisten Likes aufweist, gewinnt den Klassenwettbewerb.
                    </p>
                    <p>
                        Nachfolgend können nun Bilder für die Teilnahme hochgeladen werden. Wir wünschen allen Schulklassen viel Glück!
                    </p>
                </div>

                <iframe :src="`https://web-manager.netlify.app/#/extern/giveaway/upload?token=${session_token}`" title="Bild-Upload" style="width: 100%; height: 300px;" scrolling="no"></iframe>

                <div class="tags">
                    <span v-for="(file, index) in dropFiles" :key="index" class="tag is-primary">
                        {{ file.name }}
                        <button class="delete is-small" type="button" @click="deleteDropFile(index)"></button>
                    </span>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    export default {
        computed: {
            is_enterprise_portal() {
                return this.$cookies.get("type") == "c";
            },
            session_token() {
                console.log(this.$cookies.get('token'))
                return  this.$cookies.get('token');
            },
            header_image() {
                return this.is_enterprise_portal ?
                    "domino_wallpaper_blue.png" :
                    "domino_wallpaper_red.png";
            },
        },
        data() {
                return {
                    file: {},
                    dropFiles: []
                };
            },
            methods: {
                deleteDropFile(index) {
                    this.dropFiles.splice(index, 1);
                }
            }
    };
</script>