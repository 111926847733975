<template>
    <div>
        <section class="section">
            <div class="container">
                <div class="content">

                    <div class="columns is-centered is-desktop">
                        <div class="column is-4">
                            <div class="notification is-light has-text-centered">
                                <b-field label="Zugangscode" type="is-primary"
                                    message="Bitte gültigen Zugangscode eingeben.">
                                    <b-input v-model="code" size="is-large" maxlength="6" required></b-input>
                                </b-field>
                                <div class="notification is-danger" v-if="error">
                                    {{error}}
                                </div>

                                <!--<a >Wie erhalte ich einen Zugangscode?</a>-->

                                <button class="button is-primary is-large" @click="accessPortal"
                                    :disabled="!is_login_button_enabled">Portal
                                    betreten</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <b-loading v-if="is_loading" :is-full-page="true" v-model="is_loading" :can-cancel="false"></b-loading>
    </div>
</template>
<script>
    import {
        accessPortal
    } from '@/webapi'
    export default {
        metaInfo: {
            title: 'Einloggen ins Portal'
        },
        data: function () {
            return {
                code: null,
                error: null,
                is_loading: false
            }
        },
        methods: {
            accessPortal() {
                this.error = null
                if (!this.code) {
                    this.error = "Bitte geben Sie einen Zugangscode an"
                    return
                }
                this.is_loading = true
                accessPortal(this.code).then(resp => {
                    this.is_loading = false
                    if (resp.data.state) {
                        this.handleAccessPortalGranted()
                    } else {
                        this.error = "Zugang um Portal fehlgeschlagen. Überprüfen Sie Ihren Zugangscode."
                    }
                }).catch(error => {
                    this.is_loading = false
                    this.error = `Es ist ein Fehler aufgetreten. (${error})`
                })
            },
            handleAccessPortalGranted() {
                console.log("access granted")
                this.$cookies.set('token', this.code, "7d")
                this.$cookies.set('name', '', "1d")
                this.$parent.refreshCookieSession()
            }
        },
        computed: {
            is_login_button_enabled() {
                return this.code && this.code.length == 6
            }
        }
    }
</script>