<template>
    <div class="subpage">
        <section class="section">
            <div class="container is-max-desktop">
                <div class="level">
                <div class="level-left">
                    <div class="level-item">
                        <h1 class="title is-2">{{data.name}}</h1>
                    </div>
                </div>
                <div class="level-right">
                    <div class="level-item">
                        <a class="button is-light" @click="reset">Zurück</a>
                    </div>
                </div>
            </div>
                <div class="columns is-multiline">
                    <div class="column is-6" v-for="(sub, i) in sub_types" :key="i">
                        <div class="notification is-info has-text-centered" @click="set_technique(sub.id)">
                            <img :src="`https://swissdominoeffect.com/img/portal/${sub.pic}`">
                            <h2 class="title is-3">{{sub.name}}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<style>
    .is-active .al img {
        border: 1px solid #fff;
        filter: grayscale(0%);
    }

    .al img {
        border: 1px solid transparent;
        filter: grayscale(100%);
    }

    .breadcrumb a {
        color: white;
    }

    li {
        color: white;
    }
</style>
<style scoped>
    .notification {
        padding: 0 0 15px 0;
    }

    .notification .title {
        margin-top: 5px;
    }
</style>
<script>
    import {
        getLevel,
        isSinglePage
    } from '../portal_const'
    export default {
        created() {
            this.data = getLevel(this.level_id)
            if (isSinglePage(this.level_id)) {
                this.set_technique(this.sub_types[0].id)
            }
        },
        data() {
            return {
                data: null
            }
        },
        methods: {
            set_technique(technique_id) {
                this.$parent.set_technique(technique_id)
            },
            reset() {
                this.$parent.set_level(null)
            }
        },
        computed: {
            level_id() {
                return this.$route.params.level
            },
            name() {
                if (this.data) {
                    return this.data.name
                }
                return ''
            },
            sub_types() {
                if (this.data) {
                    let sub = this.data.sub
                    if (sub) {
                        return sub
                    }
                }
                return []
            }
        }
    }
</script>