<template>
    <footer class="footer">
        <div class="container">
            <div class="content has-text-centered is-medium">
                <p v-if="portal_name">Aktuelle Sitzung: <strong>{{portal_name}}</strong>.</p>
                <p>© {{year}} Swiss Domino Effect GmbH.</p>
            </div>
        </div>
    </footer>
</template>
<script>
    export default {
        props: ['bus'],
        data: function () {
            return {
                portal_name: null
            }
        },
        mounted() {
            this.bus.$on('cookierefresh', () => this.portal_name = this.get_portal_name())
            this.portal_name = this.get_portal_name()
        },
        methods: {
            get_portal_name() {
                return this.$cookies.get('name')
            }
        },
        computed: {
            year() {
                return new Date().getFullYear()
            }
        }
    }
</script>