<template>
    <div class="subpage">
        <section class="hero is-medium is-dark has-background">
            <img :src="`https://swissdominoeffect.com/img/background/${header_image}`"
                class="is-transparent hero-background" />
            <div class="hero-body">
                <div class="container">
                    <h1 class="title is-1">
                        Unterlagen
                    </h1>
                </div>
            </div>
        </section>

        <section class="section">
            <div class="container is-max-desktop">

                <div v-for="download in downloads" :key="download.name" style="margin-bottom: 40px;">
                    <h3 class="title is-3">{{download.name}}</h3>
                    <hr>

                    <div class="columns is-multiline">
                        <div class="column is-6" v-for="file in download.files" :key="file.file">
                            <b-button :label="file.name" type="is-dark is-outlined" size="is-medium"
                                icon-left="file-pdf-outline" @click="openFile(file.file)" title="Klicken um Datei herunterzuladen" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    export default {
        data: function () {
            return {
                downloads: [{
                    name: 'Hilfsmittel für Dominoevents',
                    files: [{
                        file: 'Konzept_Map_Dominoworkshop.pdf',
                        name: 'Konzept Map Dominoworkshop'
                    },
                    {
                        file: 'Presentation.pptx',
                        name: 'Powerpoint-Präsentation über Domino'
                    }]
                },{
                    name: 'Feldpläne zum Nachbauen',
                    files: [{
                        file: 'Alphabet.pdf',
                        name: 'Das gesamte Alphabet'
                    }, {
                        file: 'Smiley.pdf',
                        name: 'Smiley'
                    },{
                        file: 'Zahlen.pdf',
                        name: 'Alle Zahlen'
                    },{
                        file: 'Herz.pdf',
                        name: 'Herz'
                    },{
                        file: 'Richtig_Haken.pdf',
                        name: 'Richtig Haken'
                    },{
                        file: 'Falsch_Kreuz.pdf',
                        name: 'Falsch Kreuz'
                    },{
                        file: 'Schweizerkreuz.pdf',
                        name: 'Schweizerkreuz'
                    }]
                }, {
                    name: 'Feldpläne zum selber ausmalen',
                    files: [{
                            file: 'ausmalfelder/Ausmalfelder (5x15).pdf',
                            name: 'Ausmalfelder (5x15)'
                        },
                        {
                            file: 'ausmalfelder/Ausmalfelder (6x18) 2.pdf',
                            name: 'Ausmalfelder (6x18)'
                        },
                        {
                            file: 'ausmalfelder/Ausmalfelder (7x21) 3.pdf',
                            name: 'Ausmalfelder (7x21)'
                        },
                        {
                            file: 'ausmalfelder/Ausmalfelder (8x24) 4.pdf',
                            name: 'Ausmalfelder (8x24)'
                        },
                        {
                            file: 'ausmalfelder/Ausmalfelder (10x30) 5.pdf',
                            name: 'Ausmalfelder (10x30)'
                        }
                    ]
                }]
            }
        },
        methods: {
            openFile(file) {
                window.open(`https://swissdominoeffect.com/downloads/portal/${file}`, "_blank")
            }
        },
        computed: {
            is_enterprise_portal() {
                return this.$cookies.get('type') == 'c'
            },
            header_image() {
                return this.is_enterprise_portal ? 'domino_wallpaper_blue.png': 'domino_wallpaper_black.png'
            }
        }
    }
</script>