<template>
    <div class="random">
        <h3 class="title is-3">Was könnte ich als nächtes Bauen?</h3>
        <hr>
        <div class="columns">
            <div class="column is-6">
                <p class="subtitle is-4">Fällt dir gerade nicht ein was du als nächtes bauen könntest? Wir helfen dir,
                    mit
                    unserem Zufallspicker für Bauideen.</p>
                <button class="button is-primary is-outlined is-medium" @click="handleRandomPick">Klick mich für einen
                    Vorschlag</button>
            </div>
            <div class="column is-6">
                <div class="notification is-info has-text-centered" v-if="random_idea" @click="goToRandomPick" title="Klicke hier um zu der Technik zu gelangen">
                    <h5 class="title is-5">Dein Zufallspick ist:</h5>
                    <img :src="`https://swissdominoeffect.com/img/portal/${random_idea.technique.pic}`">
                    <h2 class="title is-2">{{random_idea.technique.name}}</h2>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    .random {
        margin-bottom: 40px;
    }

    .notification {
        padding: 20px;
    }
</style>
<script>
    import {
        randomIdea
    } from './../portal_const'
    export default {
        data: function () {
            return {
                random_idea: null
            }
        },
        methods: {
            handleRandomPick() {
                this.random_idea = randomIdea()
            },
            goToRandomPick() {
                console.log(this.random_idea)
                let level_id = this.random_idea.level_id
                let technique_id = this.random_idea.technique.id
                this.$router.push({
                    name: 'portal',
                    params: {
                        subpage: 'techniken',
                        level: level_id,
                        id: technique_id
                    }
                })
            }
        }
    }
</script>