import {
    accessPortal,
    namePortal
} from '@/webapi'

const isCookieSessionValid = function (cookieToken, callback) {
    if (cookieToken) {
        accessPortal(cookieToken).then(resp => {
            callback(resp.data)
        }).catch(error => {
            console.log(`error during session check ${error}`)
            callback(false)
        })
    }
    return callback(false)
}

const sessionName = function(cookieToken, callback) {
    if(cookieToken) {
        namePortal(cookieToken).then(resp => {
            if(resp.data.state) {
                callback(resp.data.name)
            } else {
                callback('')
            }
        })
    }
}

export {
    isCookieSessionValid,
    sessionName
}